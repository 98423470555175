import { useState, Suspense, useEffect, lazy } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import Login from "./Pages/Login";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Components/Navbar";
import Loadingscreen from "./Components/Loadingscreen";
import DesignerAppBar from "./Pages/DesignerPages/DesignerAppBar";
import UserNav from "./Components/UserNav";
// import DyeChemGrn from "./Pages/UserPage/PurchaseOrder/DyeChemGrn";
// import Grn from "./Pages/UserPage/PurchaseOrder/Grn";
//import Revision from "./Pages/UserPage/OrderConfirm/Revision";
//import OcEntry from "./Pages/UserPage/OrderConfirm/OcEntry";
//import SamplePrint from "./Pages/UserPage/QualityMaster/SamplePrint";
//import QualityMaster from "./Pages/UserPage/QualityMaster/QualityMaster";
// import ItemMaster from "./Pages/UserPage/ItemMaster/ItemMaster";
//import NewUserNav from "./Components/NewUserNav";
// import UserHome from "./Pages/UserPage.js/UserHome";
// import DesignerHome from "./Pages/DesignerPages/DesignerHome";
// import EditUsers from "./Pages/AdminPages/EditUsers";
// import AdminHome from "./Pages/AdminPages/AdminHome";
// import UserCreation from "./Pages/AdminPages/UserCreation";
// import UserList from "./Pages/AdminPages/UserList";
const AdminHome = lazy(() => import("./Pages/AdminPages/AdminHome"));
const UserCreation = lazy(() => import("./Pages/AdminPages/UserCreation"));
const UserList = lazy(() => import("./Pages/AdminPages/UserList"));
const EditUsers = lazy(() => import("./Pages/AdminPages/EditUsers"));
const DesignerHome = lazy(() => import("./Pages/DesignerPages/DesignerHome"));
const UploadImage = lazy(() => import("./Pages/DesignerPages/UploadImage"));
const UserHome = lazy(() => import("./Pages/UserPage/UserHome"));
const ItemMaster = lazy(() => import("./Pages/UserPage/ItemMaster/ItemMaster"));
//quality master
const QualityMaster = lazy(() =>
  import("./Pages/UserPage/QualityMaster/QualityMaster")
);
const SamplePrint = lazy(() =>
  import("./Pages/UserPage/QualityMaster/SamplePrint")
);
//order confirm
const OcEntry = lazy(() => import("./Pages/UserPage/OrderConfirm/OcEntry"));
const Revision = lazy(() => import("./Pages/UserPage/OrderConfirm/Revision"));
//purchase
const YarnPurchaseEntry = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/YarnPurchaseEntry")
);
const FabricPurchaseEntry = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/FabricPurchaseEntry")
);
const JobberOrd = lazy(() => import("./Pages/UserPage/JobberOrder/JobberOrd"));
const Grn = lazy(() => import("./Pages/UserPage/PurchaseOrder/Grn"));
const ProformaInvoice = lazy(() =>
  import("./Pages/UserPage/ProformaInvoice/ProformaInvoice")
);
const CourierInvoice = lazy(() =>
  import("./Pages/UserPage/Courier/CourierInvoice")
);
const DyeChemiclePur = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/DyeChemiclePur")
);
const SamplePur = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/SamplePurchase")
);
const DyeChemGrn = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/DyeChemGrn")
);
const YarnGrn = lazy(() => import("./Pages/UserPage/PurchaseOrder/YarnGrn"));
const SampleGrn = lazy(() =>
  import("./Pages/UserPage/PurchaseOrder/SampleGrn")
);
const App = () => {
  const token = localStorage.getItem("jjtoken");
  const [open, setOpen] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isAdminAuth, setIsAdminAuth] = useState(false);
  const [isdesignerAuth, setIsdesignerAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function checkAuthStatus() {
    try {
      const checkapicall = await fetch("/api/v1/verify", {
        method: "GET",
        headers: {
          jjtoken: token,
        },
      });
      const response = await checkapicall.json();
      if (response.status && response.payload.roleid === "U") {
        setIsAuth(true);
      } else if (response.status && response.payload.roleid === "A") {
        setIsAdminAuth(true);
      } else if (response.status && response.payload.roleid === "D") {
        setIsdesignerAuth(true);
      } else {
        <Redirect to="/" />;
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    checkAuthStatus();
  }, []);
  return (
    <>
      {isLoading && <Loadingscreen />}
      {!isLoading && !isAuth && !isAdminAuth && !isdesignerAuth && (
        <Login
          setIsAuth={setIsAuth}
          setIsAdminAuth={setIsAdminAuth}
          setIsdesignerAuth={setIsdesignerAuth}
        />
      )}
      {!isLoading && isAdminAuth && (
        <>
          <Navbar
            open={open}
            setIsAdminAuth={setIsAdminAuth}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Suspense fallback={<Loadingscreen />}>
            <Switch>
              <Route
                exact
                path="/adminhome"
                render={(props) =>
                  isAdminAuth ? (
                    <AdminHome {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/userlist"
                render={(props) =>
                  isAdminAuth ? (
                    <UserList {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/usercreation"
                render={(props) =>
                  isAdminAuth ? (
                    <UserCreation {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
              <Route
                exact
                path="/editusers"
                render={(props) =>
                  isAdminAuth ? (
                    <EditUsers {...props} open={open} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </Switch>
          </Suspense>
        </>
      )}
      {!isLoading && isAuth && (
        <>
          <UserNav
            setIsAuth={setIsAuth}
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          {/* <NewUserNav /> */}
          <Suspense fallback={<Loadingscreen />}>
            <Switch>
              <Route
                exact
                path="/home"
                render={(props) =>
                  isAuth ? (
                    <UserHome {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/item"
                render={(props) =>
                  isAuth ? (
                    <ItemMaster {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/quality"
                render={(props) =>
                  isAuth ? (
                    <QualityMaster {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/sampleprint"
                render={(props) =>
                  isAuth ? (
                    <SamplePrint {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/ocentry"
                render={(props) =>
                  isAuth ? (
                    <OcEntry {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/revision"
                render={(props) =>
                  isAuth ? (
                    <Revision {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/yarnpurchase"
                render={(props) =>
                  isAuth ? (
                    <YarnPurchaseEntry {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/fabpurchase"
                render={(props) =>
                  isAuth ? (
                    <FabricPurchaseEntry {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/grn"
                render={(props) =>
                  isAuth ? (
                    <Grn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/jobberorder"
                render={(props) =>
                  isAuth ? (
                    <JobberOrd {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/profinvc"
                render={(props) =>
                  isAuth ? (
                    <ProformaInvoice {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/courier"
                render={(props) =>
                  isAuth ? (
                    <CourierInvoice {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/samplepur"
                render={(props) =>
                  isAuth ? (
                    <SamplePur {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/dyechempur"
                render={(props) =>
                  isAuth ? (
                    <DyeChemiclePur {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/dyechemgrn"
                render={(props) =>
                  isAuth ? (
                    <DyeChemGrn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/yarngrn"
                render={(props) =>
                  isAuth ? (
                    <YarnGrn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
              <Route
                exact
                path="/samplegrn"
                render={(props) =>
                  isAuth ? (
                    <SampleGrn {...props} open={open} />
                  ) : (
                    <Redirect to="/home" />
                  )
                }
              />
            </Switch>
          </Suspense>
        </>
      )}
      {!isLoading && isdesignerAuth && (
        <>
          <DesignerAppBar setIsdesignerAuth={setIsdesignerAuth} />
          <Suspense fallback={<Loadingscreen />}>
            <Switch>
              <Route
                exact
                path="/designerhome"
                render={(props) =>
                  isdesignerAuth ? (
                    <DesignerHome {...props} open={open} />
                  ) : (
                    <Redirect to="/designerhome" />
                  )
                }
              />
              <Route
                exact
                path="/disgneruploadimg"
                render={(props) =>
                  isdesignerAuth ? (
                    <UploadImage {...props} open={open} />
                  ) : (
                    <Redirect to="/designerhome" />
                  )
                }
              />
            </Switch>
          </Suspense>
        </>
      )}
    </>
  );
};

export default App;
