import * as React from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BuildIcon from "@mui/icons-material/Build";
import ConstructionIcon from "@mui/icons-material/Construction";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Link } from "react-router-dom";
import "../Css/Sidebar.css";
import { NavLink } from "react-router-dom";
// import { useEffect } from "react";
import { useState, useEffect } from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// const CustomTreeItem = styled(
//   React.forwardRef((props, ref) => (
//     // <TreeItem2
//     //   ref={ref}
//     //   {...props}
//     //   slotProps={{
//     //     label: {
//     //       id: `${props.itemId}-label`,
//     //     },
//     //   }}
//     // />
//   ))
// )(({ theme }) => ({
//   color:
//     theme.palette.mode === "light"
//       ? theme.palette.grey[200]
//       : theme.palette.grey[200],
//   [`& .${treeItemClasses.content}`]: {
//     borderRadius: theme.spacing(0.5),
//     padding: theme.spacing(0.5, 1),
//     margin: theme.spacing(0.2, 0),
//     [`& .${treeItemClasses.label}`]: {
//       fontSize: "0.8rem",
//       fontWeight: 500,
//     },
//   },
//   [`& .${treeItemClasses.iconContainer}`]: {
//     borderRadius: "50%",
//     backgroundColor:
//       theme.palette.mode === "light"
//         ? alpha(theme.palette.primary.main, 0.25)
//         : theme.palette.primary.dark,
//     color: theme.palette.mode === "dark" && theme.palette.primary.contrastText,
//     padding: theme.spacing(0, 1.2),
//   },
//   [`& .${treeItemClasses.groupTransition}`]: {
//     marginLeft: 15,
//     paddingLeft: 18,
//     borderLeft: `1px dashed ${alpha(theme.palette.common.white, 1)}`,
//   },
// }));
const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[200]
      : theme.palette.grey[200],
  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: "0.8rem",
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: "50%",
    backgroundColor:
      theme.palette.mode === "light"
        ? alpha(theme.palette.primary.main, 0.25)
        : theme.palette.primary.dark,
    color: theme.palette.mode === "dark" && theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1.2),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.common.white, 1)}`,
  },
}));
const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: "#2c3e50",
  background: "-webkit-linear-gradient(to right, #2c3e50, #3498db)",
  background: "linear-gradient(to right, #2c3e50, #3498db)",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
//ACCORDION STYLING MUI
// const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   "&:not(:last-child)": {
//     borderBottom: 0,
//   },
//   "&:before": {
//     display: "none",
//   },
// }));
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      sx={{
        marginTop: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <Typography variant="caption" sx={{ color: "white" }}>
        &copy; DGS INFOTECH {currentYear}
      </Typography>
    </Box>
  );
};
const Navbar = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
  setIsAdminAuth,
}) => {
  const [loginUsername, setLoginUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [branchName, setbranchName] = useState("");
  const [finyear, setFinyear] = useState("");
  const jjtoken = localStorage.getItem("jjtoken");
  const history = useHistory();
  async function getDashdata() {
    try {
      const data = await fetch("/api/v1/dashboard", {
        method: "GET",
        headers: {
          jjtoken: jjtoken,
        },
      });
      const res = await data.json();
      console.log(res[0].username);
      setLoginUserName(res[0].username);
      setCompanyName(res[0].companyname);
      setbranchName(res[0].branchname);
      setFinyear(res[0].finname);
      //console.log(res);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getDashdata();
  }, [])

  const theme = useTheme();
  const treeData = [
    {
      id: "1",
      label: "Admin",
      children: [
        {
          id: "a1",
          label: "Dashboad",
          path: "/adminhome",
        },
        { id: "a2", label: "Usercreation", path: "/usercreation" },
        {
          id: "a3",
          label: "Userlist",
          path: "/userlist",
          // children: [
          //   { id: "a2", label: "Subledger", path: "/subledger" },
          // ],
        },
      ],
    },
    // {
    //   id: "2",
    //   label: "TRANSACTION",
    //   children: [
    //     { id: "b1", label: "Journal Voucher", path: "/journalvoucher" }, // Custom icon for b1
    //     {
    //       id: "b2",
    //       label: "Receipt Payment Voucher",
    //       path: "/receiptpaymentvoucher",
    //     },
    //   ],
    // },
    // {
    //   id: "3",
    //   label: "REPORTS",
    //   children: [
    //     { id: "c1", label: "JOURNAL BOOK", path: "/jbook" }, // Non-expandable node
    //     // {
    //     //   id: "c2",
    //     //   label: "c2",
    //     // },
    //   ],
    // },
  ];
  const renderTreeItem = (node) => {
    const label = node.path ? (
      <NavLink
        to={node.path}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {node.label}
      </NavLink>
    ) : (
      node.label
    );

    return (
      <CustomTreeItem key={node.id} itemId={node.id} label={label}>
        {node.children && node.children.map(renderTreeItem)}
      </CustomTreeItem>
    );
  };
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("jjtoken");
    localStorage.removeItem("utype");
    setIsAdminAuth(false);
    history.push("/");
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: "2023" }} open={open}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="inherit" noWrap component="div">
              {`Welcome   ${loginUsername}   to   ${companyName}, Branch-  ${branchName}, For-   ${finyear}`}
              </Typography>
            </Box>
            <Button
              size={"small"}
              variant={"contained"}
              color={"warning"}
              onClick={(e) => logout(e)}
              btnText={"Logout"}
            >
              LogOut
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "#2c3e50",
              background: "-webkit-linear-gradient(to right, #2c3e50, #3498db)",
              background: "linear-gradient(to right, #2c3e50, #3498db)",
              color: "white",
              zIndex: "2023",
            },
            "& .MuiPaper-root": {
              zIndex: "4000",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography color={"white"} marginRight={6}>
                MAIN MENU
              </Typography>
            </Link>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Box sx={{ minHeight: 352, minWidth: 250 }}>
            <SimpleTreeView>{treeData.map(renderTreeItem)}</SimpleTreeView>
          </Box>
          <Footer />
        </Drawer>
      </Box>
    </>
  );
};

export default Navbar;
