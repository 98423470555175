import React, { useState, useEffect } from "react";
import bg from "../Assets/flat-lay-fabric-texture.jpg";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import LockIcon from "@mui/icons-material/Lock";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Login = ({ setIsAuth, setIsAdminAuth, setIsdesignerAuth }) => {
  const history = useHistory();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    body: {
      fontFamily: "Arial, sans-serif",
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      margin: "0",
      position: "relative",
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "1",
    },
    loginContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      zIndex: "2",
    },
    loginBox: {
      background: "rgba(255, 255, 255, 0.1)",
      backdropFilter: "blur(10px)",
      padding: "2rem",
      borderRadius: "8px",
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
      maxWidth: "400px",
      width: "100%",
      border: "1px solid rgba(255, 255, 255, 0.3)",
    },
    loginBoxSmall: {
      padding: "1rem",
      borderRadius: "4px",
    },
    h2: {
      marginBottom: "1.5rem",
      textAlign: "center",
      color: "white",
    },
    inputGroup: {
      marginBottom: "1.5rem",
    },
    label: {
      display: "block",
      marginBottom: "0.5rem",
      fontWeight: "bold",
      color: "white",
    },
    input: {
      width: "100%",
      padding: "0.75rem",
      border: "1px solid rgba(255, 255, 255, 0.3)",
      borderRadius: "8px",
      background: "rgba(255, 255, 255, 0.2)",
      color: "white",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(5px)",
      outline: "none",
      transition: "border-color 0.3s",
    },
    inputFocus: {
      borderColor: "navy",
    },
    loginButton: {
      width: "100%",
      padding: "0.75rem",
      backgroundColor: "navy",
      color: "white",
      border: "none",
      borderRadius: "4px",
      fontSize: "1rem",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    loginButtonSmall: {
      fontSize: "0.9rem",
    },
  };
  const [finyear, setFinyear] = useState([]);
  const [finId, setFinId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  async function handlefinyearfetch() {
    try {
      const data = await fetch("/api/v1/finyear");
      const response = await data.json();
      setFinyear(response);
      // Set the default financial year to 2024-2025
      const defaultFinyear = response.find(
        (year) => year.finname === "2024-2025"
      );
      if (defaultFinyear) {
        setFinId(defaultFinyear.finyearid);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    handlefinyearfetch();
  }, []);
  //login
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = { username, password, finyearid:finId };
      const response = await fetch("/api/v1/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();

      if (parseRes.token) {
        localStorage.setItem("jjtoken", parseRes.token);
        localStorage.setItem("utype", parseRes.utype);
        if (parseRes.utype === "A") {
          setIsAdminAuth(true);
          history.push("/adminhome");
        } else if (parseRes.utype === "D") {
          setIsdesignerAuth(true);
          history.push("/designerhome");
        } else if (parseRes.utype === "U") {
          setIsAuth(true);
          history.push("/home");
        }
      } else {
        //setRedirectToDashboardStatus(false);
        toast.error(parseRes, { autoClose: 2000 });
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <>
      <ToastContainer />
      <div style={styles.body}>
        <div style={styles.overlay}></div>
        <div style={styles.loginContainer}>
          <div
            style={{
              ...styles.loginBox,
              ...(isSmallScreen ? styles.loginBoxSmall : {}),
            }}
          >
            <h2 style={styles.h2}>Login</h2>
            <form>
              <div style={styles.inputGroup}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    flexDirection: "column",
                    rowGap: 4,
                  }}
                >
                  <TextField
                    id="Finyear"
                    required
                    select
                    label="Finyear"
                    value={finId}
                    onChange={(e) => setFinId(e.target.value)}
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                        borderRadius: "5px",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#ffffff",
                      },
                      "& .MuiInputBase-input": {
                        color: "#ffffff",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "rgba(255, 255, 255, 0.5)",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "rgba(255, 255, 255, 0.7)",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#ffffff",
                      },
                    }}
                    size="small"
                  >
                    {finyear.map((finyear) => (
                      <MenuItem
                        key={Object.values(finyear)[0]}
                        value={Object.values(finyear)[0]}
                      >
                        {Object.values(finyear)[1]}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                        borderRadius: "5px",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#ffffff",
                      },
                      "& .MuiInputBase-input": {
                        color: "#ffffff",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "rgba(255, 255, 255, 0.5)",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "rgba(255, 255, 255, 0.7)",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#ffffff",
                      },
                    }}
                    id="UserName"
                    label="Username"
                    variant="outlined"
                    size="small"
                    value={username}
                    onInput={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                        borderRadius: "5px",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#ffffff",
                      },
                      "& .MuiInputBase-input": {
                        color: "#ffffff",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "rgba(255, 255, 255, 0.5)",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "rgba(255, 255, 255, 0.7)",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#ffffff",
                      },
                      borderRadius: 50,
                    }}
                    id="Pass"
                    label="Password"
                    variant="outlined"
                    size="small"
                    type="password"
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                  />
                </Box>
              </div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
