import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"; // Make sure you have this import
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar({ setIsdesignerAuth }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory(); // Hook for navigation
  const [isAuth, setIsAuth] = React.useState(true); // Sample state for authentication
  const token = localStorage.getItem("jjtoken");
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [loginUsername, setLoginUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [branchName, setbranchName] = useState("");
  const [finyear, setFinyear] = useState("");
  const jjtoken = localStorage.getItem("jjtoken");
  async function getDashdata() {
    try {
      const data = await fetch("/api/v1/dashboard", {
        method: "GET",
        headers: {
          jjtoken: jjtoken,
        },
      });
      const res = await data.json();
      console.log(res[0].username);
      setLoginUserName(res[0].username);
      setCompanyName(res[0].companyname);
      setbranchName(res[0].branchname);
      setFinyear(res[0].finname);
      //console.log(res);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getDashdata();
  }, []);
  const logout = (e) => {
    //e.preventDefault();
    localStorage.removeItem("jjtoken");
    localStorage.removeItem("utype");
    setIsdesignerAuth(false);
    history.push("/");
  };

  const handleMenuItemClick = (setting) => {
    if (setting === "Logout") {
      logout(); // Call the logout function
    } else {
      console.log(`Clicked on ${setting}`);
      // Add specific logic for other settings here
    }
    handleCloseUserMenu();
  };

  return (
    <AppBar position="fixed" sx={{ boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <Typography variant="inherit" noWrap component="div">
            {`Welcome   ${loginUsername}   to   ${companyName}, Branch-  ${branchName}, For-   ${finyear}`}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Avatar" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleMenuItemClick(setting)}
                >
                  <Typography variant="body1" color="inherit">
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
