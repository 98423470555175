import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "../Css/Loadingscreen.css";

const Loadingscreen = ({ open }) => {
  const [loadingText, setLoadingText] = useState("");
  useEffect(() => {
    // Simulate loading process
    const timer = setTimeout(() => {
      setLoadingText();
    }, 2000);

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);
  return (
    <>
      <Box
        sx={{
          marginTop: "4rem",
        }}
      >
        <div class="loading-screen">
          <div class="loading-spinner"></div>
          <p class="loading-text">Please wait...</p>
        </div>
      </Box>
    </>
  );
};

export default Loadingscreen;
